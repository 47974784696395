import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'app/api-services/api.service';
import { MetaService } from 'app/meta.service';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-extend-user-order-plan',
  templateUrl: './extend-user-order-plan.component.html',
  styleUrls: ['./extend-user-order-plan.component.scss']
})
export class ExtendUserOrderPlanComponent {

  public extendUserOrderPlanRequestForm: FormGroup;

  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  userOrderPlanId: String = null;
  extensionOnTypeDefaultValue = 'SESSIONS'
  extensionOnTypes = [
    // { value: '', viewValue: 'All' },
    { value: 'DAYS', viewValue: 'Days' },
    { value: 'SESSIONS', viewValue: 'Sessions' }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExtendUserOrderPlanComponent>, 
    public meta: MetaService,
    public apiService: ApiService, 
    private _formBuilder: FormBuilder, 
    private toastr: ToastrService,
  ) {

  }

  ngOnInit(){
    this.meta.dialogClosed = false
    this.buildForms()
  }

  public buildForms(){
    this.extendUserOrderPlanRequestForm = this._formBuilder.group({
      extensionOnType: new FormControl(this.extensionOnTypeDefaultValue, [Validators.required]),
      extensionUnits: new FormControl(null, [Validators.required]),
      extensionReason: new FormControl(null, [Validators.required, Validators.maxLength(150)]),
    });
  }

  allowNumkeyPress(event: any) {
    const pattern = /[0-9]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  isValidToExtend() {
    return this.extendUserOrderPlanRequestForm.controls.extensionOnType.valid && 
      this.extendUserOrderPlanRequestForm.controls.extensionUnits.valid &&
      this.extendUserOrderPlanRequestForm.controls.extensionReason.valid
  }

  extendUserOrderPlan(){
    let requestBody = {
      "extension_on_type": this.extendUserOrderPlanRequestForm.controls.extensionOnType.value,
      "extension_units": this.extendUserOrderPlanRequestForm.controls.extensionUnits.value,
      "extension_message": this.extendUserOrderPlanRequestForm.controls.extensionReason.value
    }
    this.apiService.extendUserOrderPlanAPI(
      this.data.data.userOrderPlanId, 
      requestBody
    ).subscribe((response) => {
      if (response.status == "success") {
        this.meta.dialogClosed = true
        this.dialogRef.close()
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
      }
    );

  }

}
