import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { IUserActions, MetaService, featureConstants } from 'app/meta.service';
import { user } from 'app/mock-api/common/user/data';
import { ViewUserComponent } from '../view-user/view-user.component';
import { ToastrService } from 'ngx-toastr';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import {VerifyDeactivateComponent} from 'app/pages/commonComponents/verify-deactivate/verify-deactivate.component';
import { ViewUserAccessesComponent } from 'app/pages/userAccesses/view-user-accesses/view-user-accesses.component';
import { UpdateUserRoleComponent } from 'app/pages/Users/update-user-role/update-user-role.component';
export interface Element {
  Name: string;
  RoleType: string;
  IsRemoved: boolean;
  CreatedBy: string;
  gender: string;
}
export interface Iuser {
  id: String;
  is_removed: boolean;
  name: string;
  email: string;
  mobile: boolean;
  // AddBranch: boolean;
}
/** Constants used to fill up our data base. */
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent {


  dataSource = new MatTableDataSource<Iuser>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['Name', 'uniqueCodeCol', 'LastActiveTime', 'email', 'mobile', 'alias', 'branch', 'referredBy', 'Actions'];
  
  userDetails = [];
  userList: Iuser[];
  roleList = [];
  roleType = [
    // { value: '', viewValue: 'All' },
    { value: 'true', viewValue: 'Active' },
    { value: 'false', viewValue: 'In Active' }

  ]

  IUser: IUserActions = {
    userRole: "",
    userBranch: "",
    userStatus: "",
  }
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  Branch_id = "";
  search = "";
  branches_list:[];
  userBranchNames=[];
  scrHeight: number;
  scrWidth: number;
  filterValueToDisplay="";
  userAllowedFeatures = getuserAllowedFeatures()
  isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToViewRoles = this.userAllowedFeatures.indexOf(featureConstants.VIEW_ROLES) > -1
  isUserAllowedToUserCreate = this.userAllowedFeatures.indexOf(featureConstants.CREATE_USERS) > -1
  isUserAllowedToUserEdit = this.userAllowedFeatures.indexOf(featureConstants.EDIT_USERS) > -1
  isUserAllowedToUserDeactivate = this.userAllowedFeatures.indexOf(featureConstants.DEACTIVATE_USERS) > -1
  isUserAllowedToUserReactivate = this.userAllowedFeatures.indexOf(featureConstants.REACTIVATE_USERS) > -1
  isUserAllowedToViewPayables = this.userAllowedFeatures.indexOf(featureConstants.VIEW_USER_PAYABLES) > -1
  isUserAllowedToViewSchedules = this.userAllowedFeatures.indexOf(featureConstants.VIEW_SCHEDULES) > -1
  isUserAllowedToViewPurchaseHistory = this.userAllowedFeatures.indexOf(featureConstants.VIEW_PURCHASE_HISTORY) > -1
  isUserAllowedToViewUserWallet = this.userAllowedFeatures.indexOf(featureConstants.VIEW_USER_WALLETS) > -1
  isUserAllowedToViewUserAccesses = this.userAllowedFeatures.indexOf(featureConstants.VIEW_USER_ACCESSES) > -1
  isUserAllowedToEditUserAccesses = this.userAllowedFeatures.indexOf(featureConstants.EDIT_USER_ACCESSES) > -1
  isUserAllowedToUpdateRole = this.userAllowedFeatures.indexOf(featureConstants.UPDATE_USER_ROLE) > -1

  constructor(private router: Router, public dialog: MatDialog, public apiService: ApiService, private _liveAnnouncer: LiveAnnouncer, public meta: MetaService, private toastr: ToastrService, private route: ActivatedRoute,) {
    this.getScreenSize();

    // const users = Array.from({length: 100}, (_, k) => this.createNewUser(k + 1));
    // this.dataSource = new MatTableDataSource(users);
    // Create 100 users

    // Assign the data to the data source for the table to render
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.Branch_id = params['branch_id'];
    });
    const paginationDataString = sessionStorage.getItem('paginationDataForUser');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationDataForCoupon');
    
    if (paginationDataString) { 
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.filterValue=paginationData.filterValue;
      this.IUser.userRole=paginationData.filterByRole;
      this.IUser.userStatus=paginationData.filterByActiveUser;
      this.IUser.userBranch=paginationData.filterByBranchId;
      this.filterValueToDisplay=paginationData.filterValue
    }
    if (this.Branch_id) {
      this.filterUser();
      this.getRoles();
    } else {
      if (this.isUserAllowedToViewRoles) {
        this.getRoles();
      }
      this.filterUser();
    }
    if (this.isUserAllowedToViewBranches) {
      this.getBranches()
    }
  }
  resetFilter() {
    this.Branch_id = "";
    this.IUser.userRole = "";
    this.IUser.userStatus = "";
    this.dataSource.filter = ""
    this.search = ""
    this.router.navigateByUrl('Admin/users');
    this.filterUser()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // this.getAd(); // This is get ad on every time screen is resized
  }
  filterValue = ""
  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    // }
    this.onFilterChangeForSearch()
  
  }
  getBranches() {
    let data = {filter_by_branch_active_status: true}
    this.apiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branches_list = resp.data.branches
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getRoles() {
    let data = { "filter_by_role_active_status": true }
    this.apiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleList = resp.data.roles;
        // this.dataSource.data = resp.data
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }


  filterUser() {
    let data = {
        filter_by_role_ids: this.IUser.userRole,
        filter_by_user_active_status: this.IUser.userStatus,
        offset: this.bOffset, 
        limit: this.bLimit,
        filter_by_branch_ids: this.IUser.userBranch,
        search_term:this.filterValue || null
    };

    this.apiService.getUserByFilter(data).subscribe((resp) => {
        if (resp.status === "success") {
            this.dataSource.data = resp.data.users;

            this.dataSource.data.forEach((element: any) => {
                let branchNames = [];
                element.branches.forEach(ele => {
                    branchNames.push(ele.name);
                });
                element.branchNames = branchNames;
            });

            this.totalCount = resp.data.total_users_count;
        }
    }, (err) => {
        this.toastr.error(err.error.message);
    });
}


  selectRoleType(value) {
  }
  createUser() {
    sessionStorage.removeItem('editUserId')
    this.router.navigateByUrl('Admin/create-user');
  }
  editUser(row_id) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByRole:this.IUser.userRole,
      filterByActiveUser:this.IUser.userStatus,
      filterByBranchId: this.IUser.userBranch,
    };
    sessionStorage.setItem('paginationDataForUser', JSON.stringify(paginationData));
    sessionStorage.setItem('editUserId', row_id)
    this.router.navigateByUrl('Admin/edit-user');
  }

  getUserById(row) {
    this.userDetails = [];
    this.apiService.getUserById(row.id).subscribe((resp) => {
      if (resp.status == "success") {
        this.userDetails.push(resp.data.user);
        this.viewUser(this.userDetails)
      }

    },
      (err) => {
        this.toastr.error(err.error.message)
      })

  }
  viewWidth:any
  viewUser(data) { 
    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }
    let dialogRef = this.dialog.open(ViewUserComponent, {
      data: {
        data: data,
      },
      // height: auto,
      width: this.viewWidth,
    });
  }

  verifyDeactivate(user_id:string){
    let dialogRef = this.dialog.open(VerifyDeactivateComponent, {
      data: {
        "message": "User will be removed from his assigned branches. After reactivating we have to assign them manually."
      }, // Pass data to the dialog
      width: '30%',
    });
    dialogRef.afterClosed().subscribe(result => {

      if (this.meta.dialogClosed) {
        this.deleteUser(user_id=user_id)
      }
    });
  }



  deleteUser(user_id: String) {
    this.apiService.deleteUser(user_id = user_id).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data.map(
          user => {
            if (user.id == user_id)
              user.is_removed = true
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  reactivateUser(user_id: String) {
    this.apiService.reactivateUser(user_id = user_id).subscribe((resp) => {
      if (resp.status == "success") {
        // this.router.navigateByUrl('Admin/role-management');
        // window.location.reload();
        // this.toastr.success(resp.message);
        this.dataSource.data.map(
          user => {
            if (user.id == user_id)
              user.is_removed = false
          }
        )
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

  getUserPayables(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByRole:this.IUser.userRole,
      filterByActiveUser:this.IUser.userStatus,
      filterByBranchId: this.IUser.userBranch,

    };
    sessionStorage.setItem('paginationDataForUser', JSON.stringify(paginationData));
    sessionStorage.setItem('UserPayablesForUserId', row.id);
    sessionStorage.setItem('UserPayablesForUserName', row.full_name);
    const  state = {userId: row}
   
    this.router.navigate(['Admin/get-user-payable'], {state})
  }

  getPlanSchedules(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByRole:this.IUser.userRole,
      filterByActiveUser:this.IUser.userStatus,
      filterByBranchId: this.IUser.userBranch,

    };
    sessionStorage.setItem('paginationDataForUser', JSON.stringify(paginationData));
    sessionStorage.setItem('userSchedulesUserId', row.id);
    sessionStorage.removeItem('userSchedulesUserOrderPlanId')
    sessionStorage.removeItem('userSchedulesPlanTypeAlias')
    this.router.navigate(['Admin/user-schedules'])
  }

  getUserpurchaseHistory(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByRole:this.IUser.userRole,
      filterByActiveUser:this.IUser.userStatus,
      filterByBranchId: this.IUser.userBranch,

    };
    sessionStorage.setItem('paginationDataForUser', JSON.stringify(paginationData));
    sessionStorage.setItem('userPurchaseHistoryForUserId', row.id);
    sessionStorage.setItem('userPurchaseHistoryForUserName', row.full_name);
    this.router.navigate(['Admin/user-purchase-history'])
  }

  getUserAttendance(row){ 
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByRole:this.IUser.userRole,
      filterByActiveUser:this.IUser.userStatus,
      filterByBranchId: this.IUser.userBranch,

    };

    sessionStorage.setItem('paginationDataForUser', JSON.stringify(paginationData));
    sessionStorage.setItem('userAttendanceForUserId', row.id);
    this.router.navigate(['Admin/view-user-attendance'])
  }

onPaginateChange(event: PageEvent): void {

  this.bLimit = event.pageSize;
  this.bOffset = (event.pageIndex) * event.pageSize;
  this.pageIndex = event.pageIndex;

  this.filterUser(); 
}

onFilterChangeForSearch() {
  this.pageIndex = 0;
  this.bOffset = 0;
  const paginationData = {
    pageIndex: this.pageIndex,
    bOffset: this.bOffset,
    filterValue: this.filterValue,
    filterByRole:this.IUser.userRole,
    filterByActiveUser:this.IUser.userStatus,
    filterByBranchId: this.IUser.userBranch,

  };
  sessionStorage.setItem('paginationDataForUser', JSON.stringify(paginationData));
  this.filterUser()
}

  onFilterChange() {
    this.pageIndex = 0;
    this.bOffset = 0;
    this.filterValue=null;
    this.search="";
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByRole:this.IUser.userRole,
      filterByActiveUser:this.IUser.userStatus,
      filterByBranchId: this.IUser.userBranch,
    };
    sessionStorage.setItem('paginationDataForUser', JSON.stringify(paginationData));
    this.filterUser()
  }
  
  getUserWallets(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByRole:this.IUser.userRole,
      filterByActiveUser:this.IUser.userStatus,
      filterByBranchId: this.IUser.userBranch,
    };
    sessionStorage.setItem('paginationDataForUser', JSON.stringify(paginationData));
    sessionStorage.setItem('UserWalletForUserId', row.id);
    sessionStorage.setItem('UserWalletForUserName', row.full_name);
    const  state = {userId: row}
   
    this.router.navigate(['Admin/user-wallets-list'], {state})
  }

  getUserSpecificAccessesById(userId){
    this.apiService.getUserAccessesById(userId).subscribe((resp) => {
      if(resp.status == "success"){
        this.viewUserAccessesPopup(resp.data.features)
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

  viewUserAccessesPopup(userAccessesFeatures){
    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }
    let dialogRef = this.dialog.open(ViewUserAccessesComponent, {
      data: {
        userAccessesFeatures: userAccessesFeatures,
      },
      width: this.viewWidth
    });
  }

  editUserSpecificAccessesById(userObj){
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
      filterByRole:this.IUser.userRole,
      filterByActiveUser:this.IUser.userStatus,
      filterByBranchId: this.IUser.userBranch,
    };
    sessionStorage.setItem('paginationDataForUser', JSON.stringify(paginationData));
    sessionStorage.setItem('editUserAccessesForUserId', userObj.id);
    sessionStorage.setItem('editUserAccessesForUserName', userObj.full_name);
    this.router.navigateByUrl('Admin/edit-user-accesses');
  }

  openUserUpdateRolePage(userId){

    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }

    let filterByRoleActiveStatus = true
    this.apiService.getAvailableRolesToUpdateUserRoleAPI(filterByRoleActiveStatus=filterByRoleActiveStatus).subscribe((response) => {
      if (response.status == "success") {
        let allowedRoleObjs = response.data.roles;

        let dialogRef = this.dialog.open(UpdateUserRoleComponent, {
          data: {
            "userId": userId,
            "allowedRoleObjs": allowedRoleObjs,
          },
          // height: auto,
          width: this.viewWidth,
        });

        dialogRef.afterClosed().subscribe(result => {
          if (this.meta.dialogClosed) { 
            // We can peform any action here on success
            this.filterUser()
          }
        });
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
      }
    );
  }

}
