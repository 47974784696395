import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AddOnPlans, AddOnTypes, attendanceApi, branchApis, Coupons, CouponTypes, deviceApi, LeavesApi, levelApi, plans, planSubTypes, planTypes, planWaitlistsAPIUrls, purchaseHistoryAPIUrls, referralPercentagesUrls, reschedules, roleApis, schedules, Tags, Tickets, TimeslotDiscounts, userAccessesApis, UserApis, userBranches, userOrdersUrls, UserPayables, userProfile, userWalletUrls } from './api-urls';
import { ResourceServiceService } from './resource-service';
import { user } from '../mock-api/common/user/data';
import { environment } from "environments/environment";
import { getOrCreateDeviceId } from '../utils/auth_utils';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  access_token: string;

  public apiUrl: string = environment.apiUrl;

  headers = new HttpHeaders({
    "device-id": getOrCreateDeviceId(),
    "device-type": "WEB_APP",
  })
  constructor(public router: Router, public httpClient: HttpClient, public resourceService: ResourceServiceService) {
  }
  ngOnInit() {
    this.access_token = "Bearer" + localStorage.getItem('accessToken');
  }

  protected requestGet(path: string, httpParams?: any): Observable<any> {

    const url = this.apiUrl + path;
    const filteredValue = {}
    if (httpParams) {
      for (let [key, value] of Object.entries(httpParams)) {
        if (value || value === 0) {
          filteredValue[key] = value
        }
      }
    }
    const base = this.httpClient
      .get(url, {
        headers: this.headers,
        params: filteredValue
      },)
      .pipe(catchError(this.handleError));
    const request = base.pipe(
      map((response) => {
        return response;
      })
    );
    return request;
  }
  // post api
  protected requestPost(path: any, param: any, HttpParams: any = false, queryParams: any = false): Observable<any> {
    if (HttpParams) {
      //Adding path params in url - Send httpParams only when path params are present
      console.log("HttpParams---", HttpParams)
      path = path(HttpParams);

      console.log("I am in params and the path is--", path)

    }
    const url = this.apiUrl + path;


    
    if (!queryParams){
        queryParams = {}
    }

    const base = this.httpClient
      .post(url, param, {
        headers: this.headers,params: queryParams
      })
      .pipe(catchError(this.handleError));
    const request = base.pipe(
      map((response) => {
        return response;
      })
    );
    return request;
  }
  //  Error Handeling
  protected handleError(errorResponse: HttpErrorResponse) {
    let errorMessage = '';
    if (errorResponse.error instanceof ErrorEvent) {
      errorMessage = `Error: Client Side Error: ${errorResponse.error.message}`;
    } else {
      // errorMessage = `Error: Server side error: ${errorResponse.error.message}`;
      // errorMessage = "Server side error: There is a problem with the server. We are notified & working on it. Please try again later!"
    }

    //         window.alert(errorMessage);
    return throwError(errorResponse);
  }
  // RolesApi's
  public getRolesByFilter(data) {
    const base = this.requestGet('api/roles', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  // const base = this.requestGet('api/users',data).pipe(catchError(this.handleError));
  // const request = base.pipe(
  //   map((data) => {
  //     return data;

  //   })
  // );
  // return request
  public getRoleById(value) {
    let role_id = value
    const base = this.resourceService.requestGet(roleApis.getRoleById, { role_id: role_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public viewRoleById(value) {
    let role_id = value
    const base = this.resourceService.requestGet(roleApis.viewRoleById, { role_id: role_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getRoles() {
    const base = this.resourceService.requestGet(roleApis.getRoles).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getFeatures() {
    const base = this.resourceService.requestGet(roleApis.getFeatures).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getAllUserFeatures() {
    const base = this.resourceService.requestGet(roleApis.getAllUserFeatures).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getUsers(queryParams) {
    const base = this.resourceService.requestGet(UserApis.getUser, queryParams=queryParams).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getUserById(value) {
    let user_id = value
    const base = this.resourceService.requestGet(UserApis.getUserById, { user_id: user_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getStates() {
    const base = this.resourceService.requestGet(UserApis.getStates).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getBranches() {
    const base = this.resourceService.requestGet(UserApis.getBranches).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getBranchByFilter(data) {
    const base = this.requestGet('api/branches', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getCities(value) {
    let stateId = value;
    const base = this.resourceService.requestGet(UserApis.getCities, { stateId: stateId }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  // filter Users api
  public getUserByFilter(data) {
    const base = this.requestGet('api/users', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public createUser(payload) {
    const base = this.requestPost(UserApis.createUser, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public updateUser(payload, user_id) {
    const base = this.resourceService.requestPatch(UserApis.updateUser + '/' + user_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public updateUserFiles(payload, user_id) {
    const base = this.resourceService.requestPost(UserApis.updateUserFilesURL, payload, { user_id: user_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public createBranch(payload) {
    const base = this.requestPost(branchApis.createBranch, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public updateBranch(payload, branch_id) {
    const base = this.resourceService.requestPatch(branchApis.updateBranch + '/' + branch_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public updateBranchFiles(payload, branch_id) {
    const base = this.resourceService.requestPost(branchApis.updateBranchFilesURL, payload, { branch_id: branch_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getOtpUser(payload) {
    const base = this.requestPost(UserApis.userOtp, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public createRole(payload) {
    const base = this.requestPost(roleApis.createRole, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public updateRole(payload, role_id) {
    // return this.httpClient.patch(this.apiUrl + "api/roles/"+role_id , payload,{headers:this.headers}).pipe(
    //   map((response) => {
    //     return response;        
    //   }),
    //   catchError((err) => {
    //     throw err;
    //   })
    // )
    const base = this.resourceService.requestPatch(roleApis.updateRole + '/' + role_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getBranchById(value) {
    let branch_id = value
    const base = this.resourceService.requestGet(branchApis.getBranchById, { branch_id: branch_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public deactivateRole(role_id: String) {
    let payload = {};
    const base = this.requestPost(roleApis.deleteRole, payload, { role_id: role_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivateRole(role_id: String) {
    let payload = {};
    const base = this.requestPost(roleApis.reactivateRole, payload, { role_id: role_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public deleteUser(user_id: String) {
    let payload = {};
    const base = this.requestPost(UserApis.deleteUser, payload, { user_id: user_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivateUser(user_id: String) {
    let payload = {};
    const base = this.requestPost(UserApis.reactivateUser, payload, { user_id: user_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public deactivateBranch(branch_id: String) {
    let payload = {};
    const base = this.requestPost(branchApis.deactivateBranch, payload, { branch_id: branch_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivateBranch(branch_id: String) {
    let payload = {};
    const base = this.requestPost(branchApis.reactivateBranch, payload, { branch_id: branch_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getUserByBranch(branch_id) {
    const base = this.resourceService.requestGet(branchApis.getUserByBranch, { branch_id: branch_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getUserProfile() {
    const base = this.resourceService.requestGet(userProfile.getUserProfile).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public updateUserProfile(reqBody) {

    const base = this.resourceService.requestPatch(userProfile.updateUserProfile, reqBody).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  
  public generateOtpUserUpdate(payload, user_id): Observable<any> {
    const base = this.resourceService.requestPost(UserApis.generateOtpUserUpdate, payload, { user_id: user_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getUserAttendance() {
    const base = this.resourceService.requestGet(attendanceApi.getAttendance).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getUserAttendanceById(queryParams, user_id) {
    const base = this.resourceService.requestGet(attendanceApi.getUserAttendanceById, {user_id: user_id}, queryParams).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getUserLogsById(log_date, user_id, branchId) {
    let queryParams = { log_date: log_date }
    if (branchId != null) {
      queryParams['filter_by_branch_id'] = branchId
    }
    const base = this.resourceService.requestGet(attendanceApi.getLogsById, { user_id: user_id }, queryParams).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public updateLogsById(payload, user_id) {
    const base = this.resourceService.requestPost(attendanceApi.updateLogsById, payload, { user_id: user_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getDevicesList() {
    const base = this.resourceService.requestGet(deviceApi.getDevicesList).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public createDevice(payload) {
    const base = this.resourceService.requestPost(deviceApi.createDevice, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getDeviceById(device_id) {
    const base = this.resourceService.requestGet(deviceApi.getDeviceById, { attendance_device_id: device_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public updateDevice(payload, device_id) {
    const base = this.resourceService.requestPatch(deviceApi.updateDevice + '/' + device_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public deactivateDevice(device_id: String) {
    let payload = {};
    const base = this.requestPost(deviceApi.deactivateDevice, payload, { attendance_device_id: device_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivateDevice(device_id: String) {
    let payload = {};
    const base = this.requestPost(deviceApi.reactivateDevice, payload, { attendance_device_id: device_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getDeviceByFilter(data) {
    const base = this.requestGet('api/attendance/devices', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getLevels(){
    const base = this.resourceService.requestGet(levelApi.getAllLevels).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public createLevel(payload){
    const base = this.resourceService.requestPost(levelApi.createLevel, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public updateLevel(payload, level_id){
    const base = this.resourceService.requestPatch(levelApi.updateLevel + '/' + level_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  filterUserAttendance(data){
    const base = this.requestGet('api/users/attendance/last/active', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  getUsersBranches(user_id){
    const base = this.resourceService.requestGet(userBranches.getUserBranches, { user_id: user_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  getUsersDevices(user_id, queryParams){
    const base = this.resourceService.requestGet(userBranches.getUserDevices, { user_id: user_id }, queryParams).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  getPayrollReport(requestBody,queryParams) {
    const base = this.requestPost('api/users/payroll', requestBody, false, queryParams).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  getLogsReport(data){
    const base = this.requestPost('api/users/attendance/logs/report', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getUserByFilterReport(data) {
    const base = this.requestPost('api/users/filter', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getPlanTypeDetails() {
    const base = this.resourceService.requestGet(planTypes.getPlanTypes).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public createPlanType(payload) {
    const base = this.resourceService.requestPost(planTypes.createPlanType, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public updatePlanType(payload, plan_type_id){
    const base = this.resourceService.requestPatch(planTypes.updatePlanType + '/' + plan_type_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public deactivatePlanType(plan_type_id: String) {
    let payload = {};
    const base = this.requestPost(planTypes.deactivatePlanType, payload, { plan_type_id: plan_type_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivatePlanType(plan_type_id: String) {
    let payload = {};
    const base = this.requestPost(planTypes.reactivatePlanType, payload, { plan_type_id: plan_type_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getPlanTypeByFilter(data) {
    const base = this.requestGet('api/plan-types', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getPlanSubTypeByFilter(data) {
    const base = this.requestGet('api/plan-subtypes', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getAddOnPlanByFilter(data) {
    const base = this.requestGet('api/addons', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getPlanSubTypeDetails() {
    const base = this.resourceService.requestGet(planSubTypes.getPlanSubTypes).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public createPlanSubType(payload,plan_type_id) {
    const base = this.requestPost(planSubTypes.createPlanSubType, payload, { plan_type_id: plan_type_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getPlanSubTypeById(value) {
    let plan_subtype_id = value
    const base = this.resourceService.requestGet(planSubTypes.getPlanSubTypeById, { plan_subtype_id: plan_subtype_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public deactivatePlanSubType(plan_subtype_id: String) {
    let payload = {};
    const base = this.requestPost(planSubTypes.deactivatePlanSubType, payload, { plan_subtype_id: plan_subtype_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivatePlanSubType(plan_subtype_id: String) {
    let payload = {};
    const base = this.requestPost(planSubTypes.reactivatePlanSubType, payload, { plan_subtype_id: plan_subtype_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getPlanDetails() {
    const base = this.resourceService.requestGet(plans.getPlans).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public reactivatePlans(plan_id: String) {
    let payload = {};
    const base = this.requestPost(plans.reactivatePlans, payload, { plan_id: plan_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  
  public deactivatePlans(plan_id: String) {
    let payload = {};
    const base = this.requestPost(plans.deactivatePlans, payload, { plan_id: plan_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getPlansByFilter(data) {
    const base = this.requestGet('api/plans', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getPlansById(value) {
    let plan_id = value
    const base = this.resourceService.requestGet(plans.getPlansById, { plan_id: plan_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }


  public updatePlanSubType(payload, plan_subtype_id ){
    const base = this.resourceService.requestPatch(planSubTypes.updatePlanSubType + '/' + plan_subtype_id , payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public createCalculatePlansPrice(price) {
    const base = this.requestGet(plans.createCalculatePlansPrice, { price: price }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getSACCodes() {
    const base = this.requestGet(plans.sacCodes).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public createPlan(payload) {
    const base = this.resourceService.requestPost(plans.createPlans, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  

  public getAddOnPlanDetails() {
    const base = this.resourceService.requestGet(AddOnPlans.getAddOnPlans).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public deactivateAddOnPlanType(addon_plan_id: String) {
    let payload = {};
    const base = this.requestPost(AddOnPlans.deactivateAddOnPlans, payload, { addon_plan_id: addon_plan_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivateAddOnPlanType(addon_plan_id : String) {
    let payload = {};
    const base = this.requestPost(AddOnPlans.reactivateAddOnPlans, payload, { addon_plan_id : addon_plan_id  }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getAddOnPlanById(value) {
    let addon_plan_id = value
    const base = this.resourceService.requestGet(AddOnPlans.getAddOnPlansById, { addon_plan_id: addon_plan_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }


  public getAddOnPlanTypeByFilter(data) {
    const base = this.requestGet('api/addon-types', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public createAddOnPlan(payload) {
    const base = this.resourceService.requestPost(AddOnPlans.createAddOnPlans, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getAddOnTypesDetails() {
    const base = this.resourceService.requestGet(AddOnTypes.getAddOnTypes).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getAddOnTypesByFilter(data) {
    const base = this.requestGet('api/addon-types', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public deactivateAddOnType(addon_plan_type_id : String) {
    let payload = {};
    const base = this.requestPost(AddOnTypes.deactivateAddOnTypes, payload, { addon_plan_type_id : addon_plan_type_id  }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivateAddOnType(addon_plan_type_id  : String) {
    let payload = {};
    const base = this.requestPost(AddOnTypes.reactivateAddOnTypes, payload, { addon_plan_type_id  : addon_plan_type_id   }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getAddOnTypeById(value) {
    let addon_plan_type_id = value
    const base = this.resourceService.requestGet(AddOnTypes.getAddOnTypesById, { addon_plan_type_id: addon_plan_type_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getAddOnTypeByFilter(data) {
    const base = this.requestGet(AddOnTypes.getAddOnTypes, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public createAddOnType(payload) {
    const base = this.requestPost(AddOnTypes.createAddOnTypes, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public updateAddOnType(payload, addon_plan_type_id ){
    const base = this.resourceService.requestPatch(AddOnTypes.updateAddOnTypes + '/' + addon_plan_type_id , payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public updateAddOnPlans(payload, addon_plan_type_id ){
    const base = this.resourceService.requestPut(AddOnPlans.updateAddOnPlans + '/' + addon_plan_type_id , payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public updatePlan(payload,plan_id) {
    const base = this.resourceService.requestPut(plans.updatePlans+'/'+ plan_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  


  public getActiveSchedulesByFilter(data) {
    const base = this.requestGet(schedules.activeSchedulesList, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getUserSchedulesByFilter(data) {
    const base = this.requestGet(schedules.userSchedulesList, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getSchedulesUserOrderPlanById(value) {
    let user_order_plan_id  = value
    const base = this.resourceService.requestGet(schedules.schedulesUserOrderPlanInfo, {user_order_plan_id : user_order_plan_id}).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getScheduleById(slotBookingId) {
    const base = this.resourceService.requestGet(schedules.scheduleById, {slot_booking_id : slotBookingId}).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getAvailableTimeSlotsToCreateupdateSchedule(requestBody) {
    const base = this.resourceService.requestPost(schedules.getAvailableTimeSlotsToCreateupdateSchedule, requestBody).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getSchedulesAvailableTrainers(requestBody) {
    const base = this.resourceService.requestPost(
      schedules.SchedulesAvailableTrainers, requestBody,
    ).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public createSchedule(requestBody, pathParams) {
    const base = this.resourceService.requestPost(
      schedules.createSchedule, requestBody, pathParams
    ).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public updateSchedule(requestBody, pathParams) {
    const base = this.resourceService.requestPatch(
      schedules.updateSchedule, requestBody, pathParams
    ).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getReschedulesRequestsByFilter(data) {
    const base = this.requestGet(reschedules.rescheduleRequests, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getRescheduleInfoById(rescheduleSlotId) {
    let data = {
      "reschedule_slot_booking_id": rescheduleSlotId
    }
    const base = this.resourceService.requestGet(reschedules.rescheduleInfoById, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public updateReschedule(requestBody, pathParams) {
    const base = this.resourceService.requestPatch(
      reschedules.updateReschedule, requestBody, pathParams
    ).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getActiveLeavesListByFilter(data) {
    const base = this.requestGet(LeavesApi.getLeavesList, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getUserListById(value) {
    let user_id = value
    const base = this.resourceService.requestGet(LeavesApi.getUsersListById, { user_id: user_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  
  public createLeave(payload) {
    const base = this.resourceService.requestPost(LeavesApi.createLeave, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public updateLeave(payload, leave_id) {
    const base = this.resourceService.requestPatch(LeavesApi.updateLeave + '/' + leave_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public updateLeaveFiles(payload, leave_id) {
    const base = this.resourceService.requestPost(LeavesApi.updateLeaveFilesURL, payload, { leave_id: leave_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getLeaveById(leave_id) {
    const base = this.resourceService.requestGet(LeavesApi.getLeaveById, {leave_id : leave_id}).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public createLeaveStatus(payload,leave_id ) {
    const base = this.resourceService.requestPost(LeavesApi.createLeavesStatus +'/'+ leave_id +'/approve/',payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getUserLeaveByFilter(data,user_id) {
    const base = this.requestGet('api/leaves/users/'+ user_id, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getUserPayableListById(value) {
    let user_payable_id = value
    const base = this.resourceService.requestGet(UserPayables.getUserPayableListById, { user_payable_id : user_payable_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getUserPayableList(data) {
    const base = this.requestGet('api/user-payables', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public createPayable(payload) {
    const base = this.resourceService.requestPost(UserPayables.createUserPayableList, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public updatePayable(payload, user_payable_id ) {
    const base = this.resourceService.requestPatch(UserPayables.updateUserPayableList + '/' + user_payable_id , payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getCouponTypeDetails() {
    const base = this.resourceService.requestGet(CouponTypes.getCouponTypes).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public createCouponType(payload) {
    const base = this.resourceService.requestPost(CouponTypes.createCouponTypes, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public updateCouponType(payload, coupon_type_id){
    const base = this.resourceService.requestPatch(CouponTypes.updateCouponTypes + '/' + coupon_type_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public deactivateCouponType(coupon_type_id: String) {
    let payload = {};
    const base = this.resourceService.requestPost(CouponTypes.deactivateCouponTypes +'/'+ coupon_type_id+'/deactivate',payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivateCouponType(coupon_type_id: String) {
    let payload = {};
    const base = this.resourceService.requestPost(CouponTypes.reactivateCouponTypes +'/'+ coupon_type_id+'/reactivate',payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getCouponTypeByFilter(data) {
    const base = this.requestGet('api/coupon-types', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getCouponDetails() {
    const base = this.resourceService.requestGet(Coupons.getCoupons).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getCouponsByFilter(data) {
    const base = this.requestGet('api/coupons', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public createCoupon(payload) {
    const base = this.resourceService.requestPost(Coupons.createCoupon, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public updateCoupon(payload, coupon_id){
    const base = this.resourceService.requestPatch(Coupons.updateCoupon + '/' + coupon_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getCouponById(value) {
    const base = this.resourceService.requestGet(Coupons.getCouponsById, {coupon_id: value}).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public reactivateCoupon(coupon_id: String) {
    let payload = {};
    const base = this.requestPost(Coupons.reactivateCoupons, payload, { coupon_id: coupon_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  
  public deactivateCoupon(coupon_id: String) {
    let payload = {};
    const base = this.requestPost(Coupons.deactivateCoupons, payload, { coupon_id: coupon_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getTicketDetails() {
    const base = this.resourceService.requestGet(Tickets.getTickets).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getTicketsByFilter(data) {
    const base = this.requestGet('api/support/tickets', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getTags() {
    const base = this.resourceService.requestGet(Tags.getTags).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getTagsByFilter(data) {
    const base = this.requestGet('api/support/tags', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public getTagById(value) {
    const base = this.resourceService.requestGet(Tags.getTagById, {tag_id: value}).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public createTag(payload) {
    const base = this.resourceService.requestPost(Tags.createTags, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public updateTags(payload, tag_id: string) {
    const base = this.resourceService.requestPatch(Tags.updateTags+ '/' + tag_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivateTag(tag_id: String) {
    let payload = {};
    const base = this.requestPost(Tags.reactivateTag, payload, { tag_id: tag_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  
  public deactivateTags(tag_id: String) {
    let payload = {};
    const base = this.requestPost(Tags.deactivateTag, payload, { tag_id: tag_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getTicketById(value) {
    const base = this.resourceService.requestGet(Tickets.getTicketById, {ticket_id: value}).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public createTicket(payload) {
    const base = this.resourceService.requestPost(Tickets.createTickets, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public updateTicket(payload, ticket_id: string) {
    const base = this.resourceService.requestPatch(Tickets.updateTickets+ '/' + ticket_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public updateTicketFiles(payload, ticket_id) {
    const base = this.resourceService.requestPost(Tickets.updateTicketFilesURL, payload, { ticket_id: ticket_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getTimeslotDiscountsByFilter(data) {
    const base = this.requestGet('api/timeslot-discounts', data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getTimeslotDiscountsDetails() {
    const base = this.resourceService.requestGet(TimeslotDiscounts.getTimeslotDiscounts).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public createTimeslotDiscount(payload) {
    const base = this.resourceService.requestPost(TimeslotDiscounts.createTimeslotDiscounts, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public updateTimeslotDiscount(payload, timeslot_discount_id){
    const base = this.resourceService.requestPatch(TimeslotDiscounts.updateTimeslotDiscounts + '/' + timeslot_discount_id, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getTimeslotDiscountById(value) {
    const base = this.resourceService.requestGet(TimeslotDiscounts.getTimeslotDiscountById, {timeslot_discount_id: value}).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  public reactivateTimeslotDiscount(timeslot_discount_id: String) {
    let payload = {};
    const base = this.requestPost(TimeslotDiscounts.reactivateTimeslotDiscounts, payload, { timeslot_discount_id: timeslot_discount_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  
  public deactivateTimeslotDiscount(timeslot_discount_id: String) {
    let payload = {};
    const base = this.requestPost(TimeslotDiscounts.deactivateTimeslotDiscounts, payload, { timeslot_discount_id: timeslot_discount_id }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getAllTimeslotAliases() {
    const base = this.resourceService.requestGet(TimeslotDiscounts.timeSlotAliases).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }
  public getUsageReport(requestBody, queryParams) {
    const base = this.resourceService.requestPost('api/users/attendance/report', requestBody, false, queryParams).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {  
        return data;
      })
    );
    return request
  }
  public getPersonalTrainingSessions(requestBody) {
    const base = this.resourceService.requestPost('api/schedules/pts/dashboard', requestBody).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }
  public uploadFaqs(requestBody) {
    const base = this.resourceService.requestPost('api/support/faqs', requestBody).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getUserPurchaseHistory(data) {
    const base = this.requestGet(purchaseHistoryAPIUrls.getAllPurchasehistory, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public cancelPlanInfoAPI(requestBody) {
    const base = this.resourceService.requestPost(userOrdersUrls.cancelPlanInfo, requestBody).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public cancelPlanAPI(requestBody) {
    const base = this.resourceService.requestPost(userOrdersUrls.cancelPlan, requestBody).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getUserWalletTransactions(data) {
    const base = this.requestGet(userWalletUrls.userWalletTransactions, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getReferralPercentages(data) {
    const base = this.requestGet(referralPercentagesUrls.getReferralPercentages, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getReferralPercentagesById(referralPercentagesId) {
    const base = this.resourceService.requestGet(referralPercentagesUrls.getReferralPercentagesById, { referralPercentagesId: referralPercentagesId }, {}).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public createReferralPercentages(payload) {
    const base = this.resourceService.requestPost(referralPercentagesUrls.createReferralPercentages, payload).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public updateReferralPercentages(referralPercentagesId, payload){
    const base = this.resourceService.requestPatch(referralPercentagesUrls.editReferralPercentages, payload, {referralPercentagesId: referralPercentagesId}).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public deactivateReferralPercentages(referralPercentagesId: String) {
    let payload = {};
    const base = this.requestPost(referralPercentagesUrls.deactivateReferralPercentages, payload, { referralPercentagesId: referralPercentagesId }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public reactivateReferralPercentages(referralPercentagesId: String) {
    let payload = {};
    const base = this.requestPost(referralPercentagesUrls.reactivateReferralPercentages, payload, { referralPercentagesId: referralPercentagesId }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getUserAccessesById(userId) {
    const base = this.resourceService.requestGet(userAccessesApis.getUserAccessesById, { user_id: userId }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public getUserAccessesWithAllAccessesByUserId(userId) {
    const base = this.resourceService.requestGet(userAccessesApis.getUserAccessesWithAllAccessesByUserId, { user_id: userId }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public updateUserAccessesByUserId(userId, payload) {
    const base = this.resourceService.requestPatch(userAccessesApis.updateUserAccessesByUserId, payload, {user_id: userId}).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request;
  }

  public getAvailableTrainersToUpdatePlanTrainerAPI(userOrderPlanId: string) {
    const base = this.resourceService.requestGet(userOrdersUrls.getAvailableTrainersToUpdatePlanTrainer, { user_order_plan_id: userOrderPlanId }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public updatePlanTrainerAPI(userOrderPlanId: string, trainerId: string) {
    let requestBody = {"trainer_id": trainerId}
    const base = this.resourceService.requestPatch(userOrdersUrls.updatePlanTrainer, requestBody, { user_order_plan_id: userOrderPlanId }).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

  public getExpiryRenewalTracksAPI(data) {
    const base = this.requestGet(purchaseHistoryAPIUrls.getPlanExpiryRenewalTracks, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }
  
  public getPlanWaitlistsAPI(data) {
    const base = this.requestGet(planWaitlistsAPIUrls.getAllPlanWaitlists, data).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;

      })
    );
    return request
  }

  public extendUserOrderPlanAPI(userOrderPlanId, requestBody) {
    const base = this.resourceService.requestPost(
      userOrdersUrls.extendUserOrderPlan, requestBody, 
      {user_order_plan_id: userOrderPlanId}
    ).pipe(catchError(this.handleError));
    const request = base.pipe(
      map((data) => {
        return data;
      })
    );
    return request
  }

}
