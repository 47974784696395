<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
  <div class="row ">

    <div class="row mt-8 justify-content-between">
      <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
        <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
          USERS
        </h1>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end" *ngIf="isUserAllowedToUserCreate">
        <button class="addBtn" mat-flat-button [ngClass]="{'btn-clr': true}" 
        [color]="'primary'" (click)="createUser()"
        >
        <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1 txt" style="color: white">Create User</span>
      </button>
    </div>
    </div>
 
    <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-2" *ngIf="isUserAllowedToViewRoles">
            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
              <mat-select [placeholder]="'All Roles'" [(ngModel)]="IUser.userRole" (selectionChange)="onFilterChange()">
                <mat-option value="">All Roles</mat-option>
                <mat-option *ngFor="let role of roleList" [value]="role.id">{{role.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
    
          <div class="col-sm-12 col-md-4 col-lg-2">
            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
              <mat-select [placeholder]="'All'" [(ngModel)]="IUser.userStatus" (selectionChange)="onFilterChange()">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let role of roleType" [value]="role.value">{{role.viewValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-2" *ngIf="isUserAllowedToViewBranches">
            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
              <mat-select [placeholder]="'All Branches'" [(ngModel)]="IUser.userBranch" (selectionChange)="onFilterChange()">
                <mat-option value="">All Branches</mat-option>
                <mat-option *ngFor="let branch of branches_list" [value]="branch.id">{{branch.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;" [subscriptSizing]="'dynamic'">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <input matInput (keyup)="applyFilter($event)"  [autocomplete]="'off'" [placeholder]="'Search User Name'"
              [(ngModel)]="filterValueToDisplay">
            </mat-form-field>
          </div>
  </div>

  </div>


  <div class="mat-elevation-z8 mt-2 overflow-auto">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

      <!-- ID Column -->
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef  sortActionDescription="Sort by Name"> Name</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.full_name |
          titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="uniqueCodeCol">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Code"> Unique Code</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.referral_code}} </td>
      </ng-container>

      <ng-container matColumnDef="LastActiveTime">
        <th mat-header-cell *matHeaderCellDef >Last Active Time</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.last_active_date_time | dateTimeFormat}}
        </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef > Email</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.email}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef >Mobile</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.mobile_number}}
        </td>
      </ng-container>
      <ng-container matColumnDef="alias">
        <th mat-header-cell *matHeaderCellDef > Role </th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> {{row.role.name}}
        </td>
      </ng-container>
      <ng-container matColumnDef="branch">
        <th mat-header-cell *matHeaderCellDef > Branch </th>
        <td mat-cell class="txt_over_flow" matTooltipPosition="above" matTooltip={{row.branchNames}}
          [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> {{row.branchNames?.join(", ")}}
        </td>
      </ng-container>

      <ng-container matColumnDef="referredBy">
        <th mat-header-cell *matHeaderCellDef >Referred By</th>
        <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
          {{row.referred_by_user?.full_name}}<span *ngIf="row.referred_by_user?.role">({{row.referred_by_user.role.name}})</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
        <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row ">
          <!-- <button mat-icon-button (click)="getUserById(row)">
              <mat-icon style="color:#0038FF" svgIcon="heroicons_outline:eye"></mat-icon>       
            </button>
            <button mat-icon-button *ngIf="!row.is_removed" (click)="editUser()">
              <mat-icon style="color:#0FAE1F" svgIcon="heroicons_outline:pencil"></mat-icon>
            </button>
            <button mat-icon-button *ngIf="!row.is_removed" (click)="deleteUser(row.id)">
              <mat-icon style="color:#FF0000" svgIcon="heroicons_outline:trash"></mat-icon>
            </button>
            <button mat-icon-button *ngIf="row.is_removed" (click)="reactivateUser(row.id)">
              <mat-icon style="color:#0000FF" svgIcon="heroicons_outline:refresh"></mat-icon>
            </button> -->

            <button mat-button [matMenuTriggerFor]="menu"><mat-icon style="color:#0038FF;font-size: 30px;"
              svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="getUserById(row)">View</button>
            <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToUserEdit" (click)="editUser(row.id)">Edit</button>
            <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToUserDeactivate" (click)="verifyDeactivate(row.id)">Deactivate</button>
            <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToUserReactivate" (click)="reactivateUser(row.id)">Reactivate</button>
            <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToUpdateRole" (click)="openUserUpdateRolePage(row.id)">Update Role</button>
            <button mat-menu-item  *ngIf="isUserAllowedToViewPayables" (click)="getUserPayables(row)">User Payables</button>
            <button mat-menu-item *ngIf="isUserAllowedToViewSchedules" (click)="getPlanSchedules(row)">User Schedules</button>
            <button mat-menu-item *ngIf="isUserAllowedToViewPurchaseHistory" (click)="getUserpurchaseHistory(row)">User Purchase History</button>
            <button mat-menu-item  (click)="getUserAttendance(row)">User Attendance</button>
            <button mat-menu-item  *ngIf="isUserAllowedToViewUserWallet" (click)="getUserWallets(row)">User Wallet</button>
            
            <button mat-menu-item  *ngIf="isUserAllowedToViewUserAccesses || isUserAllowedToEditUserAccesses" [matMenuTriggerFor]="userAccessesMenu">
              User Accesses
              <mat-menu #userAccessesMenu="matMenu">
                <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToViewUserAccesses" (click)="getUserSpecificAccessesById(row.id)">View User Accesses</button>
                <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToEditUserAccesses" (click)="editUserSpecificAccessesById(row)">Edit User Accesses</button>
              </mat-menu>
            </button>

          </mat-menu>
        </td>


      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <div>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="12">
            <h3 style="display: flex;justify-content: center; margin:10%;">
              No Records Found
            </h3>
          </td>
        </tr>
      </div>
    </table>
  </div>
  <mat-paginator style="display:flex;justify-content: end;" 
  (page)="onPaginateChange($event)" [length]="totalCount"
  [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
  aria-label="Select page of users" showFirstLastButtons></mat-paginator>
  
</div>