<!-- <h1  mat-dialog-title>View User</h1> -->
<div class="bgCard">

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>Extend User Plan</h1>
           <button align="end" mat-icon-button mat-dialog-close >
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>       
          </button>
    </div>
    <div>
        <mat-dialog-content class="mat-typography" >

            <div class="card dataCard mt-4" [formGroup]="extendUserOrderPlanRequestForm">
                <div class="mt-2 ml-3 ">
                    <h4 class="headings">
                        Extend User plan
                    </h4>
                    <div class="row mr-3 ml-3">
                        <div class="col-md-4">
                            <div class="fw-bold fieldName">
                                Extension Type
                                <span class="text-danger">*</span>
                            </div>
                            <div class="">
                                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                                    <mat-select id="extensionOnTypeId" style="background: #FFFFFF;" matInput
                                    formControlName="extensionOnType" required>
                                        <mat-option *ngFor="let extensionType of extensionOnTypes" [value]="extensionType.value">{{extensionType.viewValue}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="fw-bold fieldName">
                                Extension Units
                                <span class="text-danger">*</span>
                            </div>
                            <div class="">
                                <mat-form-field class="w-full">
                                    <input id="extensionUnits" style="background: #FFFFFF;" matInput placeholder="Enter extension units"
                                        formControlName="extensionUnits" required
                                        (keypress)="allowNumkeyPress($event)"
                                    >
                                    <mat-error *ngIf="extendUserOrderPlanRequestForm.get('extensionUnits').hasError('required')">
                                        Extension Units are required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-md-8">
                            <div class="fw-bold fieldName">
                                Extension Reason
                                <span class="text-danger">*</span>
                                <span style="font-weight: lighter;" class="mobileNumberstyle"> (Maxlength(150))</span>
                            </div>
                            <div class="">
                                <mat-form-field class="w-full">
                                    <input 
                                    matInput
                                    id="extensionReason"  
                                    style="background: #FFFFFF;" placeholder="Extension Reason"
                                    formControlName="extensionReason"
                                    (keypress)="handleStartingSpaceTextInput($event, extendUserOrderPlanRequestForm.get('extensionReason').value)"
                                    />
                                    <mat-error *ngIf="extendUserOrderPlanRequestForm.get('extensionReason').hasError('required')">
                                        Extension Reason is required
                                    </mat-error>
                                    <mat-error *ngIf="extendUserOrderPlanRequestForm.get('extensionReason').hasError('maxlength')">
                                        Extension Reason should be less than or equal to 150 characters
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="btnposition">
                        <div class="bottomBtnsSingle mb-3 row">
                            <button class="ml-4 mr-6 addBtn" mat-flat-button [color]="'primary'" (click)="extendUserOrderPlan()"
                                [disabled]="!isValidToExtend()"
                                [ngClass]="{'btn-clr': isValidToExtend()}">
                                <span class="ml-2 mr-1 txt" style="color: white">Extend Plan</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
</div>