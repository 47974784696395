import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { MetaService } from 'app/meta.service';
import { getuserAllowedFeatures } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';
import { ViewFitnessStatsComponent } from '../view-fitness-stats/view-fitness-stats.component';

@Component({
  selector: 'app-fitness-stat-list',
  templateUrl: './fitness-stat-list.component.html',
  styleUrls: ['./fitness-stat-list.component.scss']
})
export class FitnessStatListComponent {

  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['user', 'fitnessTestId', 'steelScore', 'collectedDate', 'createdBy', 'createdDate', 'Actions'];
  dataSource = new MatTableDataSource<Element>();

  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue = "";

  scrHeight: number;
  scrWidth: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  userAllowedFeatures = getuserAllowedFeatures()
  // isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1

  constructor(
    private router: Router, 
    public dialog: MatDialog,
    public meta: MetaService, 
    public apiService: ApiService, 
    private toastr: ToastrService) {
      this.getScreenSize();
  }

  ngOnInit() {
    this.filterFitnessScores()
  }

  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // this.getAd(); // This is get ad on every time screen is resized
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    
    this.onFilterFitnessScoresForSearch()
  }

  filterFitnessScores() {

     let limit = this.bLimit
     let offset = this.bOffset
     let searchTerm = this.filterValue || null

    this.apiService.getFilterFitnessScoresByFilterAPI(
      limit=this.bLimit, offset=this.bOffset, searchTerm=this.filterValue
    ).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data = resp.data.fitness_scores
        this.totalCount = resp.data.total_count
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }


  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
    this.filterFitnessScores(); 
  }

  onFilterFitnessScoresForSearch(){
    this.pageIndex=0;
    this.bOffset=0;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterValue: this.filterValue,
    };
    sessionStorage.setItem('paginationDataForFitnessScores', JSON.stringify(paginationData)); 
    
    this.filterFitnessScores();
  }

  viewWidth:any
  viewFitnessScore(row) { 
    if(this.scrWidth < 1450){
      this.viewWidth='80%'
    }else{
      this.viewWidth='50%'
    }
    let dialogRef = this.dialog.open(ViewFitnessStatsComponent, {
      data: {
        steelFitnessTestId: row.fitness_score.id,
      },
      // height: auto,
      width: this.viewWidth,
    });
  }

}
