import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { handleStartingSpaceTextInput } from 'app/utils/auth_utils';
import { ToastrService } from 'ngx-toastr';
import { FreezeAppAlertsApiService } from '../freeze-app-alerts-api.service';
import { BranchIdNameInterface, RoleIdNameInterface, UserIdNameInterface } from '../freeze-app-alerts-interface';

@Component({
  selector: 'app-create-freeze-app-alert',
  templateUrl: './create-freeze-app-alert.component.html',
  styleUrls: ['./create-freeze-app-alert.component.scss']
})
export class CreateFreezeAppAlertComponent {
  
  handleStartingSpaceTextInput = handleStartingSpaceTextInput

  pageTitle: string = "Create Freeze App Alert"

  isUpdate = false;

  redirectTypeNotUpdatable = false

  public createFreezeAppAlertForm: FormGroup;

  branchIdNameList: BranchIdNameInterface[] = []
  selectedBranchIds: string[] = []

  roleIdNameList: RoleIdNameInterface[] = []
  selectedroleIds: string[] = []

  userIdNameList: UserIdNameInterface[] = []
  filteredUserIdNameList: UserIdNameInterface[] = []
  receiverUserIds: string[] = []
  submitButtonText: string

  redirectTypes = [
    {value: 'NO_REDIRECT', viewValue: 'No Redirect'},
    {value: 'REDIRECT_TO_URL', viewValue: 'Redirect to URL'},
    {value: 'MY_COUPONS', viewValue: 'My Coupons'},
    {value: 'CHATBOT', viewValue: 'Chat Bot'},
    {value: 'REFER_AND_EARN', viewValue: 'Refer and Earn'},
    {value: 'STEEL_COINS', viewValue: 'Steel Coins'},
    {value: 'PLAN_WAITLISTS', viewValue: 'Plan Waitlists'},
    {value: 'UPDATE_APP', viewValue: 'Update App'},
  ]
  allowedRedirectUrlForRedirectTypes = ['REDIRECT_TO_URL']
  showRedirectUrl: boolean = false

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router, 
    private freezeAppAlertApiService: FreezeAppAlertsApiService,
    private toastr: ToastrService,
  ){
    // For now we are not implementing anythin related to edit, we will do so later
    this.buildCreateFreezeAppAlertsForm();
  }

  back(){
    this.router.navigateByUrl('Admin/freeze-app-alerts');
  }

  ngOnInit() {
    this.getRoles();
    this.getBranches();
    this.getUsers();
  }

  public buildCreateFreezeAppAlertsForm() {
    this.createFreezeAppAlertForm = this._formBuilder.group({
      title: new FormControl(null, [Validators.required]),
      description: new FormControl(''),
      redirectType: new FormControl(null, [Validators.required]),
      redirectUrl: new FormControl(null),
      submitButtonText: new FormControl(null, [Validators.required]),
    });
  }

  createFreezeAppAlert(){
    if(this.createFreezeAppAlertForm.valid){
      let payload = {
        title: this.createFreezeAppAlertForm.controls.title.value,
        description: this.createFreezeAppAlertForm.controls.description.value,
        redirect_type: this.createFreezeAppAlertForm.controls.redirectType.value,
        redirect_url: this.createFreezeAppAlertForm.controls.redirectUrl.value,
        freeze_app_alert_to_user_ids: this.receiverUserIds,
        submit_button_text: this.createFreezeAppAlertForm.controls.submitButtonText.value,
      }
      this.freezeAppAlertApiService.createFreezeAppAlertAPI(payload).subscribe((resp)=>{
        if(resp.status =="success"){
          this.router.navigateByUrl('Admin/freeze-app-alerts');
        }
      },
      (err) => {
        this.toastr.error(err.error.message)
      })
    }
  }

  updatedRedirectType() {
    this.createFreezeAppAlertForm.controls.redirectUrl.reset()
    if (this.allowedRedirectUrlForRedirectTypes.indexOf(this.createFreezeAppAlertForm.controls.redirectType.value) > -1) {
      this.showRedirectUrl = true
    } else {
      this.showRedirectUrl = false
    }
  }

  isFreezeAppAlertValid(){
    let isRedirectUrlNeeded = this.allowedRedirectUrlForRedirectTypes.indexOf(this.createFreezeAppAlertForm.controls.redirectType.value) > -1
    
    let isValid = (
      this.createFreezeAppAlertForm.valid && 
      (!isRedirectUrlNeeded || (isRedirectUrlNeeded && this.createFreezeAppAlertForm.controls.redirectUrl.value && this.createFreezeAppAlertForm.controls.redirectUrl.value.length > 0))
    )

    return isValid
  }

  applyFilterOnUsers(searchValue) {
    this.filteredUserIdNameList = this.userIdNameList.filter(optionObj => 
      optionObj.full_name.toLowerCase().includes(searchValue.toLowerCase()) || this.receiverUserIds.indexOf(optionObj.id) > -1
    )
  }

  getRoles() {
    let data = {
      "filter_by_role_active_status": true
    }
    this.freezeAppAlertApiService.getRolesByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.roleIdNameList = resp.data.roles.map(role => {
          return { "id": role.id, "name": role.name }
        });
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    })
  }

  getBranches() {
    let data = {
      "filter_by_branch_active_status": true
    }
    this.freezeAppAlertApiService.getBranchByFilter(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.branchIdNameList = resp.data.branches.map(branch => {
          return {
            "id": branch.id, "name": branch.name
          }
        })
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      })
  }

  getUsers() {
    this.resetCheckedUsers()
    let data = {
      "filter_by_branch_ids": this.selectedBranchIds,
      "filter_by_role_ids": this.selectedroleIds
    }
    this.freezeAppAlertApiService.getUserByFilterReport(data).subscribe((resp) => {
      if (resp.status == "success") {
        this.userIdNameList = resp.data.users.map(user => {
          return {
            "id": user.id, "full_name": user.full_name, "mobile_number": user.mobile_number,
          }
        });
        this.filteredUserIdNameList = this.userIdNameList.map(e => e);
      }
    })
  }

  // SelecteAll checkbox for Users
  allBranchesChecked = false
  someBranchesChecked() {
    if (this.selectedBranchIds.length == this.branchIdNameList.length) {
      this.allBranchesChecked = true
    } else {
      this.allBranchesChecked = false
    }
    return this.selectedBranchIds.length > 0 && !this.allBranchesChecked;
  }
  setAllBranchesChecked(change) {
    if (change.checked) {
      this.allBranchesChecked = true;
      this.selectedBranchIds = this.branchIdNameList.map(e => e.id);
    } else {
      this.allBranchesChecked = false;
      this.selectedBranchIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Roles
  allRolesChecked = false
  someRolesChecked() {
    if (this.selectedroleIds.length == this.roleIdNameList.length) {
      this.allRolesChecked = true
    } else {
      this.allRolesChecked = false
    }
    return this.selectedroleIds.length > 0 && !this.allRolesChecked;
  }
  setAllRolesChecked(change) {
    if (change.checked) {
      this.allRolesChecked = true;
      this.selectedroleIds = this.roleIdNameList.map(e => e.id);
    } else {
      this.allRolesChecked = false;
      this.selectedroleIds = [];
    }
    this.resetCheckedUsers()
    this.getUsers()
  }

  // SelecteAll checkbox for Users
  allUsersChecked = false
  someUsersChecked() {
    if (this.receiverUserIds.length == this.userIdNameList.length) {
      this.allUsersChecked = true
    } else {
      this.allUsersChecked = false
    }
    return this.receiverUserIds.length > 0 && !this.allUsersChecked;
  }
  setAllUsersChecked(change) {
    if (change.checked) {
      this.allUsersChecked = true;
      this.receiverUserIds = this.userIdNameList.map(e => e.id);
      this.filteredUserIdNameList = this.userIdNameList.map(e => e);
    } else {
      this.allUsersChecked = false;
      this.receiverUserIds = [];
    }
  }

  // Resetting details
  resetCheckedUsers() {
    this.allUsersChecked = false;
    this.receiverUserIds = []
  }

  reset(){
    this.buildCreateFreezeAppAlertsForm()

    this.allBranchesChecked = false;
    this.selectedBranchIds = [];

    this.allRolesChecked = false;
    this.selectedroleIds = [];

    this.allUsersChecked = false;
    this.receiverUserIds = []
  }

}
