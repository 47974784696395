<div class="bgCard">
    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>VIEW FITNESS STATS</h1>
           <button align="end" mat-icon-button mat-dialog-close >
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>       
          </button>
    </div>
    <div *ngIf="fitnessScoreStatObj != null">
        <mat-dialog-content class="mat-typography" >

            <div class="card dataCard" style="width: 100%;" *ngIf="fitnessScoreStatObj.user != null">
                <div class="mt-2 ml-3 " >
                    <h1 class="headings">
                        User
                    </h1>
                    <div class="row" >
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                User Name:
                            </div> 
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.user.full_name}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Mobile Number:
                            </div>
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.user.mobile_number}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Email:
                            </div>
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.user.email}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card dataCard mt-4" style="width: 100%;" *ngIf="fitnessScoreStatObj.vo2_max != null">
                <div class="mt-2 ml-3 " >
                    <h1 class="headings">
                        VO2 Max
                    </h1>
                    <div class="row" >
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                VO2 Max Percentage:
                            </div> 
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.vo2_max.vo2_max_percentage}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                VO2 Max Percentage Rating:
                            </div>
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.vo2_max.vo2_max_percentage_rating}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Person age:
                            </div>
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.vo2_max.person_age}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card dataCard mt-4" style="width: 100%;" *ngIf="fitnessScoreStatObj.body_fat != null">
                <div class="mt-2 ml-3 " >
                    <h1 class="headings">
                        Body Fat
                    </h1>
                    <div class="row" >
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Body Fat Percentage:
                            </div> 
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.body_fat.body_fat_percentage}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Body Fat Percentage Rating:
                            </div>
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.body_fat.body_fat_percentage_rating}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Person age:
                            </div>
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.body_fat.person_age}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card dataCard mt-4" style="width: 100%;" *ngIf="fitnessScoreStatObj.bone_density != null">
                <div class="mt-2 ml-3 " >
                    <h1 class="headings">
                        Body Density
                    </h1>
                    <div class="row" >
                        <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                            <div class="listFont fw-bold">
                                Z Score:
                            </div> 
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.bone_density.z_score_value}}
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6 mb-4">
                            <div class="listFont fw-bold">
                                Z Score meaning:
                            </div>
                            <div class="fw-bold">
                                {{fitnessScoreStatObj.bone_density.z_score_meaning}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card dataCard mt-4 p-2" *ngIf="fitnessScoreStatObj.relative_strength_exercise.length > 0">
                <h1 class="headings">
                    Relative Strength Exercises
                </h1>
                <div class="card dataCard m-1 border border-black" style="width: 100%;" *ngFor="let relativeExercise of fitnessScoreStatObj.relative_strength_exercise">
                    <div class="mt-2 ml-3 ">
                        <h1 class="headings">
                            {{relativeExercise.exercise_type}}
                        </h1>
                        <div class="row" >
                            <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                                <div class="listFont fw-bold">
                                    Stronger Than Percentage:
                                </div> 
                                <div class="fw-bold">
                                    {{relativeExercise.stronger_than_percentage}}
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                                <div class="listFont fw-bold">
                                    Body Times Lift Value:
                                </div> 
                                <div class="fw-bold">
                                    {{relativeExercise.body_times_lift_value}}
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                                <div class="listFont fw-bold">
                                    Lift Rating:
                                </div> 
                                <div class="fw-bold">
                                    {{relativeExercise.lift_rating}}
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                                <div class="listFont fw-bold">
                                    Beginner Min Lift Value:
                                </div> 
                                <div class="fw-bold">
                                    {{relativeExercise.beginner_min_lift_value}}
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                                <div class="listFont fw-bold">
                                    Novice Min Lift Value:
                                </div> 
                                <div class="fw-bold">
                                    {{relativeExercise.novice_min_lift_value}}
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                                <div class="listFont fw-bold">
                                    Intermediate Min Lift Value:
                                </div> 
                                <div class="fw-bold">
                                    {{relativeExercise.intermediate_min_lift_value}}
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                                <div class="listFont fw-bold">
                                    Advanced Min Lift Value:
                                </div> 
                                <div class="fw-bold">
                                    {{relativeExercise.advanced_min_lift_value}}
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 mb-4">
                                <div class="listFont fw-bold">
                                    Elite Min Lift Value:
                                </div> 
                                <div class="fw-bold">
                                    {{relativeExercise.elite_min_lift_value}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </mat-dialog-content>
    </div>   
</div>
