<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-sm-5 col-md-4 col-lg-2">
              <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
                Fitness Scores
              </h1>
            </div>
            <div class="col-sm-6 col-md-4  d-flex justify-content-end" *ngIf="false">
                <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'" (click)="addDevice()">
                    <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                    <span class="ml-2 mr-1 txt" style="color: white">Create Device</span>
                </button>
            </div>
        </div>

<div class="row">
    <div class="col-sm-12 col-md-4 col-lg-2">
        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;" [subscriptSizing]="'dynamic'">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'" [(ngModel)]="filterValue"
                    [placeholder]="'Search User Name'">
        </mat-form-field>
     </div>
</div>
    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef>User</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.user.full_name | titlecase}}({{row.user.mobile_number}}) </td>
            </ng-container>

            <ng-container matColumnDef="fitnessTestId">
                <th mat-header-cell *matHeaderCellDef> Fitness Test Id </th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.fitness_score.fitness_test_id}} </td>
            </ng-container>

            <ng-container matColumnDef="steelScore">
                <th mat-header-cell *matHeaderCellDef>Steel Score</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.fitness_score.steel_score}} </td>
            </ng-container>

            <ng-container matColumnDef="collectedDate">
                <th mat-header-cell *matHeaderCellDef >Collected at</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.fitness_score.collected_at | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef >Created By</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.fitness_score.created_by?.full_name}}<span *ngIf="row.fitness_score.created_by?.role">({{row.fitness_score.created_by.role.name}})</span> 
                </td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef >Created Date</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.fitness_score.created_at | dateFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header >Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon style="color:#0038FF;font-size: 30px;"
                            svgIcon="heroicons_outline:dots-vertical">
                        </mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="viewFitnessScore(row)">View</button>
                    </mat-menu>
                </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="12">
                  <h3 style="display: flex;justify-content: center; margin:10%;">
                    No Records Found
                  </h3>
                  </td>
                </tr>
              </div>
        </table>
    </div>

    <!-- <mat-paginator style="display:flex;justify-content: end;" [pageSizeOptions]="[ 10, 25, 100]"
    aria-label="Select page of users"></mat-paginator> -->
    <mat-paginator style="display:flex;justify-content: end;" 
  (page)="onPaginateChange($event)" [length]="totalCount"
  [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
  aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>