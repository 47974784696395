import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'app/api-services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-fitness-stats',
  templateUrl: './view-fitness-stats.component.html',
  styleUrls: ['./view-fitness-stats.component.scss']
})
export class ViewFitnessStatsComponent {

  fitnessScoreStatObj = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: ApiService,
    private toastr: ToastrService
  ) { }
  
  ngOnInit() {
    this.getUserFitnessScoreStatsById();
  }

  getUserFitnessScoreStatsById(){

    this.apiService.getUserFitnessScoreStatAPI(
      this.data.steelFitnessTestId
    ).subscribe((resp) => {
      if (resp.status == "success") {
        this.fitnessScoreStatObj = resp.data
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }

}
