<!-- <p><b>In Development</b></p> -->
<div class="bgCard">

    <div class="dilogStl">
        <h1 class="viewHeading" mat-dialog-title>View Freeze App Alert</h1>
        <button align="end" mat-icon-button mat-dialog-close>
            <mat-icon style="color:#0038FF" svgIcon="feather:x"></mat-icon>
        </button>
    </div>

    <mat-dialog-content class="mat-typography">

        <div class="card dataCard" *ngFor="let item of freezeAppAlertsObjs">
            <div class="mt-2 ml-3">
                <h1 class="headings">
                    Basic Details
                </h1>
                <div class="row m-3">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                        <div class="listFont fw-bold">
                            Title:
                        </div>
                        <div class="fw-bold">
                            {{item.title}}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                        <div class="listFont fw-bold">
                            Description:
                        </div>
                        <div class="fw-bold">
                            {{item.description}}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                        <div class="listFont fw-bold">
                            Submit Button Text:
                        </div>
                        <div class="fw-bold">
                            {{item.submit_button_text}}
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <div class="listFont fw-bold">
                            Created By:
                        </div>
                        <div class="fw-bold">
                            {{item.created_by?.full_name}}<span
                                *ngIf="item.created_by?.role">({{item.created_by.role.name}})</span>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 mb-3">
                        <div class="listFont fw-bold">
                            Created Date:
                        </div>
                        <div class="fw-bold">
                            {{item.created_at | dateTimeFormat }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card dataCard mt-4" style="height: auto!important;">
            <div class="mt-2 ml-3 ">
                <h4 class="headings">
                    Receivers
                </h4>
                <div class="mat-elevation-z8 mt-2 overflow-auto">
                    <table mat-table [dataSource]="dataSource">

                        <ng-container matColumnDef="userName">
                            <th mat-header-cell *matHeaderCellDef>User Name</th>
                            <td mat-cell *matCellDef="let row"> {{row.full_name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="mobileNumber">
                            <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
                            <td mat-cell *matCellDef="let row"> {{row.mobile_number}} </td>
                        </ng-container>

                        <ng-container matColumnDef="seenStatus">
                            <th mat-header-cell *matHeaderCellDef>Seen Status<th>
                            <td mat-cell *matCellDef="let row"> {{seenStatusWiseWebText[row.seen_status]}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <!-- Row shown when there is no matching data. -->
                        <div>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="12">
                                    <h3 style="display: flex;justify-content: center; margin:10%;">
                                        No Records Found
                                    </h3>
                                </td>
                            </tr>
                        </div>
                    </table>
                </div>
                <mat-paginator style="display:flex;justify-content: end;" (page)="onPaginateChange($event)"
                    [length]="totalCount" [pageIndex]="pageIndex" [pageSize]="pageSize"
                    [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users" showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>
    </mat-dialog-content>
</div>