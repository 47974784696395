import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'app/api-services/api.service';
import { MetaService } from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-user-role',
  templateUrl: './update-user-role.component.html',
  styleUrls: ['./update-user-role.component.scss']
})
export class UpdateUserRoleComponent {

  title: string = "Update User Role"

  selectedRoleObj = null
  allowedRoleObjs = []
  filteredRoleObjs = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateUserRoleComponent>,
    public meta: MetaService,
    public apiService: ApiService, 
    public toastr: ToastrService, 
  ){
    this.allowedRoleObjs = this.data.allowedRoleObjs;
    this.filteredRoleObjs = this.data.allowedRoleObjs;
  }

  ngOnInit(){
    this.meta.dialogClosed = false
  }

  applyFilterOnRoles(searchValue){
    this.filteredRoleObjs = []
    if(this.selectedRoleObj){
      this.filteredRoleObjs = [this.selectedRoleObj]
    }
    this.filteredRoleObjs = this.filteredRoleObjs.concat(
      this.allowedRoleObjs.filter(optionObj => 
        optionObj.name.toLowerCase().includes(searchValue.toLowerCase()) && this.selectedRoleObj != optionObj
    ))
  }


  updateUserRoleAPICaller(){
    let userId: string = this.data.userId
    let roleId: string = this.selectedRoleObj.id

    this.apiService.updateUserRoleAPI(userId=userId, roleId=roleId).subscribe((response) => {
      if (response.status == "success") {
        this.toastr.success(response.message)
      }
    },
    (err) => {
        this.toastr.error(err.error.message)
      }
    );

    this.meta.dialogClosed = true;
    this.dialogRef.close()
  }

}
